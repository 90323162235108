import { createSlice } from '@reduxjs/toolkit';
import { PERMISSION_ERROR, STATUS_ERROR, UNRESOLVED_ERROR } from 'features/queue/grid/constants';
import { sliceNames } from './constants';

export const initialState = {
  isSaved: true,
  requiredFieldsList: [],
  isSaveStatusError: false,
  isClearRequiredField: false,
  isSaveFieldsPermissionError: false,
  isSaveUnresolvedTicketError: false,
  isSaveInProgress: false,
};

export const saveSrValidationSlice = createSlice({
  name: sliceNames.saveSrValidationSliceName,
  initialState,

  reducers: {
    setIsSrSaved: (state, action) => {
      const { status, requiredFieldsList, isClearRequiredField } = action.payload;
      if (
        (typeof status === 'string' && status === 'error') ||
        (typeof status === 'number' && !(status >= 200 && status <= 299))
      ) {
        state.isSaved = false;
        state.isSaveFieldsPermissionError = status === PERMISSION_ERROR;
        state.isSaveUnresolvedTicketError = status === UNRESOLVED_ERROR;

        if (status === STATUS_ERROR) {
          state.isSaveStatusError = true;
          state.isClearRequiredField = isClearRequiredField;
          if (requiredFieldsList && Array.isArray(requiredFieldsList) && requiredFieldsList.length) {
            state.requiredFieldsList = [...requiredFieldsList];
          }
        }
      }
    },

    resetSrSaved: (state) => {
      state.isSaved = true;
    },

    resetSrSaveStatusError: (state) => {
      state.isSaveStatusError = false;
    },

    resetSrClearRequiredField: (state) => {
      state.isClearRequiredField = false;
    },

    resetRequiredFieldsList: (state) => {
      state.requiredFieldsList = [];
    },

    resetSrSaveFieldsPermissionError: (state) => {
      state.isSaveFieldsPermissionError = false;
    },

    resetSrSaveUnresolvedTicketError: (state) => {
      state.isSaveUnresolvedTicketError = false;
    },

    setIsSaveInProgress: (state, action) => {
      state.isSaveInProgress = action.payload;
    },
  },
});

export const {
  setIsSrSaved,
  setIsSaveInProgress,
  resetSrSaved,
  resetSrSaveStatusError,
  resetSrClearRequiredField,
  resetRequiredFieldsList,
  resetSrSaveFieldsPermissionError,
  resetSrSaveUnresolvedTicketError,
} = saveSrValidationSlice.actions;

export const selectSrIsSaved = (state) => state.saveSrValidation.isSaved;
export const selectSrIsSaveInProgress = (state) => state.saveSrValidation.isSaveInProgress;
export const selectSrIsSaveStatusError = (state) => state.saveSrValidation.isSaveStatusError;
export const selectSrIsClearRequiredField = (state) => state.saveSrValidation.isClearRequiredField;
export const selectSrRequiredFieldsList = (state) => state.saveSrValidation.requiredFieldsList;
export const selectSrIsSaveFieldsPermissionError = (state) => state.saveSrValidation.isSaveFieldsPermissionError;
export const selectSrIsSaveUnresolvedTicketError = (state) => state.saveSrValidation.isSaveUnresolvedTicketError;

export default saveSrValidationSlice.reducer;
export const saveSrValidationSliceName = saveSrValidationSlice.name;
