export enum sliceNames {
  attachmentsSliceName = 'attachments',
  categoriesSliceName = 'categories',
  errorSliceName = 'error',
  fieldValidationsSliceName = 'fieldValidations',
  floatingTicketPanelSliceName = 'floatingTicketPanel',
  globalSliceName = 'global',
  dirtyFieldsSliceName = 'dirtyFields',
  keepAliveSliceName = 'keepAlive',
  messagePromptSliceName = 'messagePrompt',
  pageValidationSliceName = 'pageValidation',
  saveSrValidationSliceName = 'saveSrValidation',
  srNewSliceName = 'srNew',
  srSliceName = 'sr',
  ticketSummarySliceName = 'ticketSummary',
  userSliceName = 'user',
  activePopupsSliceName = 'activePopups',
}
