import { createSlice } from '@reduxjs/toolkit';
import { sliceNames } from './constants';

const initialState = {
  srId: -1,
  title: '',
  isSummaryTooltipOpen: false,
};

const ticketSummarySlice = createSlice({
  name: sliceNames.ticketSummarySliceName,
  initialState,
  reducers: {
    setSrDetailsForSummary: (state, { payload }) => {
      if (payload.srId !== state.srId || payload.title !== state.title) {
        state.srId = payload.srId;
        state.title = payload.title;
        state.isSummaryTooltipOpen = true;
      }
    },
    resetSrDetailsForSummary: (state) => {
      state.srId = initialState.srId;
      state.title = initialState.title;
      state.isSummaryTooltipOpen = initialState.isSummaryTooltipOpen;
    },
    setIsSummaryTooltipOpen: (state, { payload }) => {
      state.isSummaryTooltipOpen = payload;
    },
  },
});

export const { setSrDetailsForSummary, resetSrDetailsForSummary, setIsSummaryTooltipOpen } = ticketSummarySlice.actions;

export const selectSummarySrId = (allSliceState) => allSliceState.ticketSummary.srId;

export const selectSummarySrTitle = (allSliceState) => allSliceState.ticketSummary.title;

export const selectTicketSummaryDetails = (allSliceState) => ({
  srId: allSliceState.ticketSummary.srId,
  title: allSliceState.ticketSummary.title,
  isSummaryTooltipOpen: allSliceState.ticketSummary.isSummaryTooltipOpen,
});

export const selectIsSummaryTooltipOpen = (allSliceState) => allSliceState.ticketSummary.isSummaryTooltipOpen;

export default ticketSummarySlice.reducer;
export const ticketSummarySliceName = ticketSummarySlice.name;
